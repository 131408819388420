<template>
  <div class="container" :style="height">
    <div class="title">更换邮箱</div>
    <!-- 验证旧的邮箱是否正确 -->
    <div class="changemain" style="padding: 74px 0 68px;" v-if="step == 0">
      <div class="popupmain">
        <dl>
          <dt>邮箱</dt>
          <dd>
            <input type="text" placeholder="请输入旧的邮箱" v-model="value" disabled />
          </dd>
        </dl>
        <dl>
          <dt>验证码</dt>
          <dd>
            <input type="text" placeholder="请输入验证码" style="width:70%;" v-model="code" />
            <a @click="sendmessage" :class="{active:time>0}">{{sendMessageName}}</a>
          </dd>
        </dl>
        <button @click="next">下一步</button>
      </div>
    </div>
    <!-- 修改新的邮箱 -->
    <div class="changemain" style="padding: 74px 0 68px;" v-if="step == 1">
      <div class="popupmain">
        <dl>
          <dt>邮箱</dt>
          <dd>
            <input type="text" placeholder="请输入邮箱" v-model="value" />
          </dd>
        </dl>
        <dl>
          <dt>验证码</dt>
          <dd>
            <input type="text" placeholder="请输入验证码" style="width:70%;" v-model="code" />
            <a @click="sendmessageCun" :class="{active:time>0}">{{sendMessageName}}</a>
          </dd>
        </dl>
        <button @click="ExitClick">确定</button>
      </div>
    </div>
    <!-- 加载中 -->
    <div class="loading" v-if="loadshow">
      <div>
        <img src="../assets/images/loading.gif" alt />
      </div>
    </div>
  </div>
</template>
<style>
.loading {
  width: 100%;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.loading div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%); /* Firefox */
  -webkit-transform: translateX(-50%) translateY(-50%); /* Safari 和 Chrome */
  -ms-transform: translateX(-50%) translateY(-50%); /* Internet Explorer */
  -o-transform: translateX(-50%) translateY(-50%);
  background: rgba(255, 255, 255, 1);
  width: 200px;
  height: 200px;
  line-height: 200px;
  opacity: 0.5;
  border-radius: 5px;
}
</style>
<script>
// 绑定邮箱,修改绑定邮箱,绑定手机,修改绑定手机 / 获取验证码
import {
  toEmailMobile,
  getCode,
  checkCode,
  userselectOne
} from "@/assets/js/api";
export default {
  name: "changeemail",
  data() {
    return {
      height: { height: "" },
      value: "",
      code: "",
      sendMessageName: "发送验证码",
      time: 0,
      step: 0,
      loadshow: false
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    // 用户信息
    userselectOne() {
      userselectOne({}).then(result => {
        if (result.code == 0) {
          if (result.user.email) {
            this.value = result.user.email;
          } else {
            this.step = 1;
          }
        }
      });
    },
    // 倒计时60s
    get() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
        this.sendMessageName = "重新发送";
        return;
      }
      this.sendMessageName = this.time + "s重新发送";
    },
    sendmessage() {
      if (this.value == "") {
        this.$message.error("请检查您的手机号或者邮箱是否书写正确");
        return;
      } else if (this.time > 0) {
        this.loadshow = false;
      } else {
        this.loadshow = true;
      }
      if (this.time == 0) {
        getCode({
          value: this.value
        }).then(result => {
          if (result.code == 0) {
            this.time = 120;
            this.timer = setInterval(this.get, 1000);
            this.$message({
              message: "验证码发送成功，请查收短信",
              type: "success"
            });
            this.loadshow = false;
          } else {
            this.$message.error(result.msg);
            this.loadshow = false;
            return;
          }
        });
      }
    },
    sendmessageCun() {
      if (this.time == 0) {
        getCode({
          value: this.value,
          type: 1
        }).then(result => {
          if (result.code == 0) {
            this.time = 120;
            this.timer = setInterval(this.get, 1000);
            this.$message({
              message: "验证码发送成功，请查收短信",
              type: "success"
            });
          } else {
            this.$message.error(result.msg);
            return;
          }
        });
      }
    },
    // 销毁之前调用
    beforeDestroy() {
      clearInterval(this.timer);
    },
    ExitClick: function() {
      toEmailMobile({
        value: this.value,
        code: this.code
      }).then(result => {
        if (result.code == 0) {
          this.$router.go(-1);
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 下一步
    next: function() {
      if (this.value == "") {
        this.$message.error("请检查您的手机号或者邮箱是否书写正确");
        return;
      }
      checkCode({
        value: this.value,
        code: this.code
      }).then(result => {
        if (result.error) {
          this.$message.error(result.error);
        }
        if (result.code == 0) {
          clearInterval(this.timer);
          this.time = 0;
          this.sendMessageName = "发送验证码";
          this.value = "";
          this.code = "";
          this.step = 1;
        } else {
          this.$message.error(result.msg);
        }
      });
    }
  },
  mounted() {},
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
    this.userselectOne();
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
